import * as React from "react";
import { Container, Row} from "react-bootstrap";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../components/css/NewsDetail.scss";
import { Link } from "gatsby";
import { AiFillLinkedin } from 'react-icons/ai';
import { FaTwitter } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { FiArrowLeft } from 'react-icons/fi';


const EdgeqPressKit7 = () => (
  <Layout>
    <Seo title="EdgeQ | EdgeQ and Vodafone Debut" />


    <section className="News-detail">
        <Container>

                <Row>

                  <div className="detail-head">
                   <Link to="/media/#August" className="back-btn"><FiArrowLeft/> Company Announcements</Link>

                  <h2>EdgeQ and Vodafone Debut State of the Art, Software-Defined, maMIMO Open RAN Solution</h2>
                  </div>

                  <div className="detail-para">
                    
              <p className="text-left"><i>EdgeQ Sets Industry Precedence with an Open, Scalable 32TRX and 64TRX maMIMO In-Line Accelerator for Base Station Platforms </i></p>

              <div className="ajit-social">
                <ul className="social-icons">
                  <li>  
                    <a aria-label="Save" href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQFQA4el-DoX9AAAAX7t1dUotlbczx1ZDTlCKPTejfiFEWL5GGZd5oqr6zWvtFKLF3CnnFGCCLco9AXaX4nEgd1nxfFvszEVY7-NA8YXEk9NExe7VTUUStGaSa7qf9cyJahXczA=&originalReferer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fedgeq" target="_blank" rel="noopener noreferrer"><AiFillLinkedin/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://twitter.com/edgeq_inc?lang=en" target="_blank" rel="noopener noreferrer"><FaTwitter/></a>
                  </li>
                  <li>  
                    <a aria-label="Save" href="https://www.youtube.com/channel/UChEG47AbYVKYw46EGhE_dcg" target="_blank" rel="noopener noreferrer"><FaYoutube/></a>
                  </li>
                </ul>
              </div>
            <p><b>SANTA CLARA, CA</b> – Feb 27, 2023  – <Link to="/">EdgeQ Inc</Link>, a leader in 5G wireless infrastructure, has collaborated with Vodafone, a leading telecommunications mobile operator in Open Radio Access Network (ORAN), and Dell Technologies to debut a state-of-the-art ORAN-based, massive MIMO solution at this year’s Mobile World Congress in Barcelona.</p>

            <p>The collaboration and design between the three companies enables a new example of maMIMO 5G networks that leverages in line acceleration technologies to deliver high user capacity, high network bandwidth at relatively low power for the new ORAN based deployments.</p>

            <p>Hosted at the Vodafone stand, the live system comprised of a Dell PowerEdge XR11 server and an EdgeQ M-Series L1 accelerator will demonstrate impressive throughput of 5Gbps, with the accelerator drawing less than 50 watts.  The collaboration and design between the three companies demonstrate the principles of a 5G ORAN infrastructure solution on a standard server, an inline acceleration, a Radio Unit (RU) system, and third party L2/L3 software stack from collaborating companies.</p>

            <p>“Vodafone is committed to driving 5G ORAN deployments at scale. Our showcase with EdgeQ and Dell Technologies validates how open innovation can drive better performance and cost efficiencies. Technologies such as EdgeQ’s high capacity in-line L1 acceleration should enable Vodafone to scale our macro cell infrastructure to new levels of performance and efficiency without compromise.” said Paco Martin, Head of OpenRAN Product Team, Network Architecture, Vodafone.</p>

            <p>EdgeQ’s multi-node 4G/5G Base Station-on-a-Chip solution converges connectivity, compute, and networking in a disruptively innovative software-defined platform. The highly scalable, flexibly adaptive EdgeQ platform solution uniquely features a production-grade L1 stack that is open and customizable. The scalable architecture maximizes throughput performance, compute processing, across a large range of concurrent users and multiple carriers, all within a compact power and cost envelope.</p>

            <p>“EdgeQ was founded on the belief of reconstituting the network in simple and intuitive terms. Together with Vodafone and Dell Technologies, we have shown the first instantiation of a new market paradigm that scales openly and flexibly, without the cost burden and power penalties of traditional platform approaches,” said Vinay Ravuri, CEO and Founder at EdgeQ.</p>

            <p>The ORAN In-Line massive MIMO Acceleration Demo can be seen at Vodafone Booth 3E11.</p>

            <p>To learn more about how EdgeQ is pioneering the 5G infrastructure market, please visit <Link to="/">www.edgeq.io</Link>.</p>
  
              <b>About EdgeQ</b>
              <br />
              <br />
              <p>EdgeQ is a leading innovator in 5G systems-on-a-chip. The company is headquartered in Santa Clara, CA, with offices in San Diego, CA and Bangalore, India. Led by executives from Qualcomm, Intel, and Broadcom, EdgeQ is pioneering converged connectivity and AI that is fully software-customizable and programmable. The company is backed by world-renowned investors. To learn more about EdgeQ, visit <Link to="/">www.edgeq.io</Link></p>
              {/* <b>Contacts</b>
              <br />
              <br />
              <a target="_blank" href="https://goo.gl/maps/qwqsKwcTcEVokx1e6">Jordan Tewell, 10Fold Communications</a>
              <br />
              <a href="mailto:edgeq@10fold.com">edgeq@10fold.com</a>
              <br />
              <a href="tel:+4156666066">(415) 666-6066</a> */}
</div>
                </Row>
        </Container>
    </section>


  </Layout>
);

export default EdgeqPressKit7;